import { useMemo } from 'react'
import styled from 'styled-components'
import { RadioButton, RadioButtonGroup } from 'client/components/RadioButton/RadioButton'
import useField from 'client/hooks/useField'
import ErrorMessage from 'client/components/Formik/ErrorMessage/ErrorMessage'
import { DisplayPeriodType, ExhibitionType } from 'shared/constants/exhibits'
import DateRangeInput, {
  IDateRangeInputProps
} from 'client/components/DateRangeInput/DateRangeInput'
import { t } from 'client/i18n'
import { DateRange } from 'react-day-picker'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import TimezoneSelect from 'client/components/TimezoneSelect/TimezoneSelect'
import _ from 'lodash'
import { getDefaultTimezone } from 'client/screens/Catalog/forms/shared/fromToDates'
import Expiration from 'client/screens/Catalog/forms/shared/Expiration'
import { Trans } from 'react-i18next'
import TimeZoneContextualHelp from 'client/screens/Catalog/forms/shared/TimeZoneContextualHelp'

const ExhibitPeriod = styled.div`
  display: flex;
  margin-top: 16px;
`

const DateRangeContainer = styled.div`
  margin-top: 16px;
`

const StyledFormField = styled(FormField)`
  padding-left: 0;
  padding-right: 0;
  padding-top: var(--spacing-small);
  padding-bottom: 0;
`

const DisplayPeriod = () => {
  const {
    setValue: setDisplayPeriod,
    error: fieldError,
    touched: fieldTouched
  } = useField('displayPeriod')

  const { value: from, setValue: setFrom } = useField('from')
  const { value: to, setValue: setTo } = useField('to')
  const { value: lastExpiredDate, setValue: setLastExpiredDate } = useField('lastExpiredDate')
  const { value: timezone, setValue: setTimezone } = useField('timezone')
  const { value: expirationEnabled } = useField('expirationEnabled')
  const { value: type } = useField('type')
  const isTour = type === ExhibitionType.TOUR

  const dateRange: DateRange = useMemo(() => ({ from, to }), [to, from])

  const { value: displayPeriodType, setValue: setDisplayPeriodType } = useField('displayPeriodType')

  const hasError = fieldError ? fieldTouched : false

  const handleDateChange: IDateRangeInputProps['onChange'] = (range) => {
    setFrom(range?.from)
    setTo(range?.to)
    // For an exhibit that's expired before, extending it to another date will set lastExpiredDate to null so that it can be expired again.
    if (lastExpiredDate && range?.to && range?.to > new Date(lastExpiredDate)) {
      setLastExpiredDate(null)
    }
  }

  const handleDisplayPeriodChange = (value: string) => {
    setDisplayPeriodType(value)
    setDisplayPeriod(value !== DisplayPeriodType.NONE)
    // For users who didn't set from and to value before but pick Date Range, we need to give them a default tz to start with.
    if (value === DisplayPeriodType.DATE_RANGE && _.isNil(timezone)) {
      setTimezone(getDefaultTimezone())
    }
  }

  const handleTimezoneChange = (newTimezone: string) => {
    setTimezone(newTimezone)
  }

  const description = isTour ? (
    <Trans i18nKey="expireTourDescription" />
  ) : (
    <Trans i18nKey="expireExhibitDescription" />
  )

  return (
    <FormField translatable={false} label={t('Display Period')}>
      <ExhibitPeriod>
        <RadioButtonGroup
          name={displayPeriodType}
          value={String(displayPeriodType)}
          onChange={handleDisplayPeriodChange}
        >
          <RadioButton label={t('None')} value={DisplayPeriodType.NONE} />
          <RadioButton label={t('Ongoing')} value={DisplayPeriodType.ON_GOING} />
          <RadioButton label={t('Date Range')} value={DisplayPeriodType.DATE_RANGE} />
        </RadioButtonGroup>
      </ExhibitPeriod>
      {displayPeriodType === DisplayPeriodType.DATE_RANGE && (
        <>
          <DateRangeContainer>
            <DateRangeInput value={dateRange} onChange={handleDateChange} hasError={hasError} />
            {hasError && <ErrorMessage name="displayPeriod" />}
          </DateRangeContainer>
          <StyledFormField label={t('Time Zone')} additionalLabelNode={TimeZoneContextualHelp}>
            <TimezoneSelect name="timezone" onChange={handleTimezoneChange} />
          </StyledFormField>
          {expirationEnabled && (
            <StyledFormField label={t('Expiration')} description={description}>
              <Expiration />
            </StyledFormField>
          )}
        </>
      )}
    </FormField>
  )
}

export default DisplayPeriod
